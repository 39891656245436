import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_5 = {
  key: 0,
  class: "card-title me-auto ms-4"
}
const _hoisted_6 = { class: "fv-row" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = {
  href: "#",
  class: "btn btn-sm btn-light btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_13 = { class: "svg-icon svg-icon-5 m-0" }
const _hoisted_14 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4",
  "data-kt-menu": "true"
}
const _hoisted_15 = { class: "menu-item px-3" }
const _hoisted_16 = { class: "menu-item px-3" }
const _hoisted_17 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.searchItems && _ctx.searchItems(...args)), ["prevent"])),
          class: "d-flex align-items-center position-relative my-1"
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "Search",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagingFilteration.filter.searchTerm) = $event)),
            class: "form-control form-control-solid w-250px ps-15"
          }, null, 512), [
            [_vModelText, _ctx.pagingFilteration.filter.searchTerm]
          ])
        ], 32)
      ]),
      (_ctx.templateType === 'Component')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_select, {
                filterable: "",
                "allow-create": "",
                class: "w-100",
                modelValue: _ctx.contentType,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contentType) = $event)),
                onChange: _ctx.getPageList,
                placeholder: "Select an option"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templateData, (item, i) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: i,
                      label: item.contentName,
                      value: item.templateId
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.checkedMedia.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-primary",
                disabled: !_ctx.contentType && _ctx.templateType === 'Component'
              }, [
                _createVNode(_component_router_link, {
                  to: `/templatecontent/add/${_ctx.contentType || _ctx.templateId}`,
                  class: "menu-link px-3 text-white"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_10, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/Navigation/Plus.svg" })
                    ]),
                    _createTextVNode(" Add " + _toDisplayString(_ctx.templateType === 'Component' ? 'Component' : 'Content'), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ], 8, _hoisted_9)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_Datatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        onCurrentChange: _ctx.onCurrentChange,
        total: _ctx.paginationHeader?.TotalCount,
        "enable-items-per-page-dropdown": true,
        onItemsPerPageChange: _ctx.onItemsPerPageChange,
        rowsPerPage: _ctx.pagingFilteration.paging.pageSize
      }, {
        "cell-displayTitle": _withCtx(({ row: media }) => [
          _createVNode(_component_router_link, {
            to: `/templatecontent/edit/${_ctx.contentType || _ctx.templateId}/${
							media.id
						}`,
            class: "fw-bold text-gray-600 text-hover-primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(media.displayTitle), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        "cell-contentName": _withCtx(({ row: media }) => [
          _createTextVNode(_toDisplayString(media.contentName), 1)
        ]),
        "cell-contentType": _withCtx(({ row: media }) => [
          _createTextVNode(_toDisplayString(media.contentType), 1)
        ]),
        "cell-customerId": _withCtx(({ row: media }) => [
          _createTextVNode(_toDisplayString(media.customerId), 1)
        ]),
        "cell-actions": _withCtx(({ row: media }) => [
          _createElementVNode("a", _hoisted_12, [
            _createTextVNode("Actions "),
            _createElementVNode("span", _hoisted_13, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr072.svg" })
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_router_link, {
                to: `/templatecontent/edit/${_ctx.contentType || _ctx.templateId}/${
									media.id
								}`,
                class: "menu-link px-3"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Edit")
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.deleteContent(media.id)),
                class: "menu-link px-3"
              }, "Delete", 8, _hoisted_17)
            ])
          ])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "onCurrentChange", "total", "onItemsPerPageChange", "rowsPerPage"])
    ])
  ]))
}